import * as React from "react";
import { Link } from "gatsby";

import SEO from "../components/seo";

export default function AboutPage() {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title="About Me" />

      <h1>About Me</h1>

      <p>
        I'm a Christian, husband and father, front-end dev, gamer, and all
        around tech nerd!
      </p>

      <p>
        My main area of expertise is in JS (both frontend and backend). I have
        over a decade of experience working in the industry, and have the rare
        privilege of loving what I do.
      </p>

      <p>
        For more information, don't hesitate to{" "}
        <Link to="/get-in-touch">get in touch</Link>.
      </p>

      <p>
        You can also find me online at any of the links underneath the nav on
        the left.
      </p>
    </>
  );
}
